<template>
  <BaseRow>
    <BaseCol cols="12">
      <BaseHeadline size="1" fake="4" noMargin>
        {{ course.title }}
      </BaseHeadline>

      <BaseParagraph v-if="course.subtitle" noMargin class="font-bold mt-2">
        {{ course.subtitle }}
      </BaseParagraph>
    </BaseCol>

    <BaseCol cols="12">
      <CourseTopics :course-topics="course.courseTopics" />
    </BaseCol>

    <BaseCol v-if="!hideTeacher" cols="12">
      <BaseParagraph noMargin>
        {{
          $t("uc.courseDetails.byTeacher", {
            teacher: getTeacherName(course.teacher),
          })
        }}
      </BaseParagraph>
    </BaseCol>

    <BaseCol cols="12" md="6" class="md:pr-4">
      <CourseThumbnail :course="course" />
    </BaseCol>

    <BaseCol cols="12" md="6">
      <BaseParagraph>
        {{ course.description }}
      </BaseParagraph>
    </BaseCol>
  </BaseRow>
</template>

<script>
import CourseThumbnail from "@/modules/user-course-uc/components/CourseThumbnail/CourseThumbnail";
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";

export default {
  name: "CourseDetails",
  components: { CourseTopics, CourseThumbnail },
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        title: null,
        subtitle: null,
        description: null,
        thumbnailUrl: null,
        courseTopics: [],
      }),
    },
    hideTeacher: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      getTeacherName,
    };
  },
};
</script>
