<template>
  <BaseContainer fluid class="max-w-1024 mt-6">
    <BaseCard class="p-3 text-center" v-if="isLoading">
      <BaseProgressSpinner />
    </BaseCard>

    <BaseCard class="pb-3 px-3" v-else>
      <BaseRow>
        <BaseCol cols="12" class="flex justify-content-between align-items-center mb-2">
          <BaseHeadline size="1" fake="6" class="text-primary" noMargin>
            {{ $t("uc.courses.show.title") }}
          </BaseHeadline>
          <BaseButton
            iconLeft="pi pi-times"
            rounded
            text
            color="gc-color-danger"
            @click="pushBack"
          />
        </BaseCol>
      </BaseRow>

      <CourseDetails :course="course" hide-teacher />

      <BaseRow class="mt-3">
        <BaseCol cols="12" md="6">
          <BaseHeadline size="2" fake="6">{{ $t("uc.courses.show.teacher") }}</BaseHeadline>

          <BaseParagraph v-if="course.teacher">
            {{ getTeacherName(course.teacher) }}
          </BaseParagraph>
        </BaseCol>

        <BaseCol cols="12" md="6">
          <BaseHeadline size="2" fake="6">{{ $t("uc.courses.show.chapters") }}</BaseHeadline>

          <BaseParagraph>
            <ol class="pl-3">
              <li v-for="(chapter, index) in course.courseChapters" :key="index">
                {{ chapter.displayName }}
              </li>
            </ol>
          </BaseParagraph>
        </BaseCol>

        <BaseCol cols="12">
          <div class="text-right">
            <BaseHeadline size="6" fake="6" class="text-primary mb-3" noMargin>
              {{ getPriceLabel(course.price) }}
            </BaseHeadline>

            <AddCartItemButton
              :course-id="course.id"
              :has-added="course.inCart"
              :is-owned="!!course.courseOwnership"
            />
          </div>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";
import AddCartItemButton from "@/modules/user-course-uc/components/AddCartItemButton";
import BaseHeadline from "@/modules/base-components-bc/Base/BaseHeadline";
import CourseDetails from "@/modules/user-course-uc/components/CourseDetails";

export default {
  name: "CoursesShow",
  components: {
    CourseDetails,
    BaseHeadline,
    AddCartItemButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const courseId = computed(() => route.params.courseId);
    const course = ref({});

    const { isLoading, callApi, docs } = useApi({
      module: "uc",
      method: "showCourse",
      onMount: true,
      params: {
        courseId: courseId.value,
      },
      onSuccess: () => {
        course.value = docs.value.data;
      },
    });

    const pushBack = async () => {
      try {
        await router.push({
          name: "Courses",
        });
      } catch {
        return;
      }
    };

    watch(courseId, () => {
      if (courseId.value) {
        callApi({
          courseId: courseId.value,
        });
      }
    });

    return {
      courseId,
      isLoading,
      course,
      getTeacherName,
      getPriceLabel,
      pushBack,
    };
  },
};
</script>

<style lang="scss" scoped>
ol {
  list-style: decimal;
}
</style>
